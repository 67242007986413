<template>
	<!--项目-测试用例 -->
  <div class="container" style="padding: 20px;">
    <GModule>
		<GTitle name="测试用例" style="margin: 20px;"></GTitle>
		<div class="condition-box">
			<div class="state-box">
				<p>状态</p>
				<a-dropdown>
				    <template #overlay>
				        <a-menu selectable @click="handleMenuClick">
							<a-menu-item v-for="(sitem,sindex) in stateArr" :key="sindex">{{sitem}}</a-menu-item>
				        </a-menu>
				    </template>
				    <a-button>
						{{stateIndex == 'all' ? '全部' : stateArr[stateIndex]}}
				        <DownOutlined />
				    </a-button>
				</a-dropdown>
			</div>
			<div class="state-box">
				<p>开发自测状态</p>
				<a-dropdown>
				    <template #overlay>
				        <a-menu selectable @click="handleManClick">
							<a-menu-item v-for="(mitem,mindex) in manArr" :key="mindex">{{mitem}}</a-menu-item>
				        </a-menu>
				    </template>
				    <a-button>
						{{manIndex == 'all' ? '全部' : manArr[manIndex]}}
				        <DownOutlined />
				    </a-button>
				</a-dropdown>
			</div>
			<div class="state-box">
				<p>用例类型</p>
				<a-dropdown>
				    <template #overlay>
				        <a-menu selectable @click="handleCaseClick">
							<a-menu-item v-for="(nitem,nindex) in nstatusArr" :key="nindex">{{nitem}}</a-menu-item>
				        </a-menu>
				    </template>
				    <a-button>
						{{nstatusIndex == 'all' ? '全部' : nstatusArr[nstatusIndex]}}
				        <DownOutlined />
				    </a-button>
				</a-dropdown>
			</div>
			<div class="state-box">
				<p style="width: 60px;">提审时间</p>
				<a-range-picker
				    style="width: 400px"
				    :ranges="ranges"
				    show-time
				    format="YYYY-MM-DD HH:mm:ss"
					@change="change"/>
			</div>
			<a-button class="btn clo3" @click="search()">查询</a-button>
			<a-button class="btn clo1">批量通过</a-button>
		</div>
		<a-table 
			class="ttable" 
			:columns="columnsHe" 
			:data-source="data" 
			:scroll="{ x: 500,y:250 }" 
			bordered
			:pagination="false">
			<template #bodyCell="{ column ,record}">
				<template v-if="column.title === '用例ID'">
					<div class="txt">
						<p class="red"></p>
						<p>002</p>
					</div>
				</template>
				<template v-if="column.key === 'operation'">
					<div class="caozuo">
						<p>详情</p>
					</div>
				</template>
			</template>
		</a-table>
		<div class="documentFt" style="margin-top: 10px;">
		  <a-pagination size="small" :pageSize="limit" :total="total" :current="page" @change="pageChange"/>
		</div>
    </GModule>
  </div>
</template>
<script>
import dayjs from 'dayjs';
export default {
  name: 'TaskProjectTestCase',
  data(){
    return {
		activeKey:0,
		stateArr:{} ,// 状态列表
		stateIndex:'all',
		manArr:{},// 状态列表
		manIndex:'all',
		nstatusArr:{} ,// 用例类型列表
		nstatusIndex:'all',
		columnsHe: [{
		    title: '用例ID',
		    width: 80,
		    dataIndex: 'index',
		    key: 'index',
		    fixed: 'left',
		  }, {
		    title: '用例类型',
		    width: 100,
		    dataIndex: 'name',
		    key: 'describe',
		    fixed: 'left',
		  }, {
		    title: '用例摘要',
		    width: 120,
		    dataIndex: 'image',
		    key: 'image'
		  }, {
		    title: '前置条件',
		    width: 100,
		    dataIndex: 'age',
		    key: 'age'
		  }, {
		    title: '操作步骤',
		    width: 100,
		    dataIndex: 'age',
		    key: 'age'
		  }, {
		    title: '预期结果',
		    width: 100,
		    dataIndex: 'age',
		    key: 'age'
		  }, {
		    title: '提审时间',
		    width: 100,
		    dataIndex: 'age',
		    key: 'age'
		  }, {
		    title: '状态',
		    width: 100,
		    dataIndex: 'age',
		    key: 'age'
		  }, {
		    title: '开发自测状态',
		    width: 100,
		    dataIndex: 'age',
		    key: 'age'
		  },{
		    title: '操作',
		    key: 'operation',
		    fixed: 'right',
		    width: 180,
		}],
		data: [],
		limit:10,
		page:1,
		createtime:'' ,// 创建时间
		ranges: {
			'今天': [dayjs().startOf('day'), dayjs().endOf('day')],
			'本周': [dayjs().startOf('week'), dayjs().endOf('week')],
			'本月': [dayjs().startOf('month'), dayjs().endOf('month')],
			'一个月前': [dayjs().subtract(1, 'month'),dayjs().endOf('day')],
		},
		total:0 ,// 总条数
    }
  },
  async created() {
	  // 获取下拉数据
	let res = await this.$utils.api.get({
		url:'/cases/setting',
		result:1
	})
	console.log("配置项",res)
	if(res.code == 1){
		let a = {all:"全部"}
		this.stateArr = {...a,...res.data.statusList}
		this.manArr = {...a,...res.data.devStatusList}
		this.nstatusArr = {...a,...res.data.caseTypeList}
	}
  },
  methods:{
	  handleMenuClick(e){
		  this.stateIndex = e.key
	  },
	  handleManClick(e){
		  this.manIndex = e.key
	  },
	  handleCaseClick(e){
		  this.nstatusIndex = e.key
	  },
	  change(date,value){
	  	// 选择创建时间
	  	if(value[0] == '' || value[1] == ''){
	  		// 创建时间要同时满足开始时间和结束时间才可以查询
	  		this.createtime = ''
	  		return
	  	}
	  	let val = value.join(' - ') // 拼接日期
	  	this.createtime = val
	  },
	  pageChange(page,pageSize){
	  	// 切换页码时查询列表
	  	this.page = page
	  	this.getList()
	  },
	  search(){
	  	// 根据条件查询列表
	  	this.page = 1 //每次点击查询按钮都要重置页码为1
	  	this.getList()
	  },
	  async getList(){
	  	// 请求客户管理列表
	  	let info = {
	  		limit:this.limit,
	  		page:this.page,
	  		label_id:this.nstatusArr[this.nstatusIndex].id,
	  		op:{},
	  		filter:{}
	  	}
	  	if(this.nickname){
	  		info.op['sale.nickname'] = 'LIKE'
	  		info.filter['sale.nickname'] = this.nickname
	  	}
	  	if(this.createtime){
	  		info.op['createtime'] = 'RANGE'
	  		info.filter['createtime'] = this.createtime
	  	}
	  	let res = await this.$utils.api.post({
	  		url:'/contract/p_index',
	  		data:info,
	  		result:1
	  	})
	  	if(res.code == 1){
	  		this.data = res.data.rows
	  		this.total = res.data.total
	  		this.addData = res.data.extend
	  	}
	  },
  }

}
</script>

<style scoped lang="scss">
.product{
	display: flex;
	.add{
		width: 40px;
		height: 40px;
		margin-left: 20px;
		.ant-btn-icon-only{
			width: 40px !important;
			height: 40px !important;
			padding:0 !important;
		}
	}
}
.condition-box{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 5px 0 10px 0;
	.state-box{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 5px;
		p{
			margin: 0 10px 0 20px;
		}
	}
	.btn{
		margin-left: 20px;
		margin-bottom: 5px;
	}
}
.ttable{
	margin-top:0;	
	.txt{
		display: flex;
		align-items: center;
		.red{
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: red;
			margin-right: 5px;
		}
	}
}
.documentFt{
    margin-top:20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.caozuo{
	display: flex;
	justify-content: space-around;
	align-items: center;
	p{
		margin: 0;
		font-size: 12px;
		color: #407cff;
	}
}


</style>